import React from "react";

import Layout from "../layouts/default";
import SEO from "../utils/seo";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="flex justify-center">
        <h2 className="bg-yellow-400 text-2xl font-bold inline-block my-8 p-3">
          Page not found.
        </h2>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
